<template>
  <div class="box">
    <div class="header">
      <div class="header-search">
        <van-nav-bar title="错误"> </van-nav-bar>
      </div>
    </div>
    <div class="content">
      <van-empty image="error" description="页面找不到" />
      <div class="btn" @click="goPage">
        <van-button type="info" block>返回物资域</van-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'error',
  data() {
    return {
      message: '发生错误'
    };
  },
  created() {
    this.message = this.$route.query.data.msg || '发生错误'
  },
  methods: {
    goPage() {
      window.location.replace(process.env.VUE_APP_INDEX)
    }
  },
};
</script>
<style lang="less">
.btn {
  width: 100%;
  position: fixed;
  bottom: 0;
  //   text-align: center;
  //   margin-top: 50px;
}
</style>